<template >
  <div v-if="!commonInfo" class="index-loading"></div>
  <div v-else>
    <transition>
      <router-view :key="$route.fullPath"></router-view>
    </transition>
  </div>
  <div class="no-access" v-show="timeZone === -8"><p>Not available in your area!</p></div>
  
  
  
  <nut-drag 
    v-show="firstRechargeAvailable"
    direction="y" 
    class="promotions-link" 
    :style="{ top: '200px', right: '8px' }"
  >
    <div class="btn" @click="toFirstRechargeBonus()">
      <nut-countdown :endTime="firstRechargeDeadline" millisecond format="HH:mm:ss"/>
    </div>
  </nut-drag>


  <!--**************************************** 活动弹框 ****************************************-->
  <nut-popup pop-class="pop-ab" v-model:visible="abPopup">
    <div class="bd" style="background: none !important;" >

      <nut-swiper v-if="country === 'in'" :init-page="1" :pagination-visible="true"  pagination-color="#426543" auto-play="6000" :is-preventDefault="false">
        <nut-swiper-item  @click="closePopup">
          <img  :src="require('./assets/images/in/EN/S1/bg904.png')" />
        </nut-swiper-item>
      </nut-swiper>

    </div>
    <!-- <nut-button type="primary" @click="abPopup = false">{{ $t('game.gotitBtn') }}</nut-button> -->
  </nut-popup>

  <!--**************************************** 顶部下载 ****************************************-->
  <!-- <div id="downloadDialogTop" name="downloadDialogTop" style="display: none;">
    <img src="./assets/images/in/EN/S1/ggdown.jpg">
    <div class="close">X</div>
  </div> -->

  <!--**************************************** 弹窗下载 ****************************************-->
  <div class="downloadDialogPopup" id="downloadDialogPopup" name="downloadDialogPopup" v-if="showDownloadDialog" >
    <h3>{{ $t('global.downloadPopup_h3') }}</h3>
    <p>{{ $t('global.downloadPopup_p') }}</p>
    <div class="btnbox">
      <a class="btn" onclick="document.querySelector('#downloadDialogPopup').style.display= 'none'" href="javascript:void(0)">{{ $t('global.downloadPopup_later') }}</a>
      <a class="btn gg" id="googlePlayBtn" target="_blank" href="market://details?id=com.appxcel.client.yoswin">&nbsp;</a>
    </div>
  </div>

</template>
<script>

import {reactive, toRefs, onMounted, onBeforeMount} from "vue";
import {useRouter} from "vue-router";
import axios from "axios";
import {getDebugMode, getCountry, demoRestrictionsDate} from "@/assets/js/environment";
import router from './router/index.js';
import {isBlank} from "@/assets/js/commonUtil";
import {getCookie, setCookie} from "@/assets/js/cookieUtil"
import { getCommonInfoAttr } from "@/assets/js/commonInfo";

export default {
  name: "App",
  data() {
    return {
      commonInfo: null,
      country: '',
      noAccess: false,
      timeZone: '',
      navigatorLanguage: '',
      count: 0, 
      showNotice: false,
      firstRechargeAvailable: false,
      firstRechargeDeadline: null,
      abPopup: false,
    };
  },
  methods: {
    check() {
      function doCheck(a) {
        (function () {
        }["constructor"]("debugger")());
        doCheck(++a);
      }
      try {
        doCheck(0)
      } catch (err) {
        console.log(err)
      }
    },
    timeZoneStint() {
      let debugMode = getDebugMode();
      if (debugMode === 'false') {
        this.timeZone = new Date().getTimezoneOffset() / 60
        this.navigatorLanguage = navigator.language
        if (
            this.timeZone === -8 ||
            this.navigatorLanguage.indexOf('cn') >= 0 ||
            this.navigatorLanguage.indexOf('CN') >= 0 ||
            this.navigatorLanguage.indexOf('zh') >= 0 ||
            this.navigatorLanguage.indexOf('ZH') >= 0
        ) {
          this.noAccess = true
          this.check()
        }
      }
    },
    toFirstRechargeBonus(){
      router.push('/firstRechargeBonus');
    },
    closePopup(){
      this.abPopup = false; 
      const d = new Date()
      const t = d.getTime() + (1000 * 60 * 60 * 24)
      setCookie('DIWALI_20240321_2', 'close', t);
    }
  },
  mounted() {
    this.country = getCountry()
    const d = new Date()
    const startShow_in = ((d.getMonth() +1) === 3) && (d.getDate() >= 22) && (d.getDate() <= 24)
    if(this.country === 'in' && startShow_in){
      if(isBlank(getCookie('DIWALI_20240321_2'))){
        this.abPopup = false
      }
    }
  },
  created() {

    //记录来源
    if(document.referrer){
      localStorage.setItem("SOURCE_REFERRER",document.referrer);
    }
    //禁用返回
    window.history.pushState(null,null,window.document.URL);
    window.addEventListener('popstate',function(){
      window.history.pushState(null,null, window.document.URL);
    })

    this.timeZoneStint()
    demoRestrictionsDate()
    axios({
      url: "/api/system/common",
      method: "get",
    }).then((res) => {
      localStorage.setItem("commonInfo",JSON.stringify(res.data.data));
      this.commonInfo = res.data.data
      if(router.currentRoute.value.name === 'index'){
        if(this.commonInfo.pureMode && localStorage.getItem('token')  === null){
          router.push({ path: '/inviteCoupon' })
        }else{
          router.push({ path: '/'+ this.commonInfo.promoteGame })
        }
      }
      /* 首充 */
      this.firstRechargeAvailable = this.commonInfo.firstRechargeAvailable && getCountry() === 'br' && document.querySelector('body').getAttribute('s') === 's2'
      const registerTime = new Date(this.commonInfo.registerTime).getTime()
      if(this.firstRechargeAvailable){
        this.firstRechargeDeadline = Date.now() + ( (registerTime + (3600 * 1000 * (this.commonInfo.firstRechargeDuration / 60))) - new Date().getTime());
      }
    });
  },
  beforeCreate() {
    this.count = 0;
    let imgs = [
      require('./assets/images/fjbz.png'),
    ]
    for (let img of imgs) {
      let image = new Image();
      image.onload = () => {
        this.count++;
      };
      image.src = img;
    }
  },

  setup() {
    const state = reactive({
      inviteCode: null,
      param: [],
      showDownloadDialog: false
    });
    onBeforeMount(() => {
      if (window.location.href.split("?")[1]) {
        let url = window.location.href.split("?")[1];
        let arr = url.split("&");
        for (var i = 0; i < arr.length; i++) {
          state.param[arr[i].split("=")[0]] = arr[i].split("=")[1];
        }
        //版本号
        if (state.param.version) {
          if(!isBlank(getCommonInfoAttr('appxcellVersion')) && state.param.version !== getCommonInfoAttr('appxcellVersion')){
            state.showDownloadDialog = true
            //document.getElementById('downloadDialogPopup').style.display = 'block';
          }      
        }
        //邀请码
        if (state.param.invite) {
          localStorage.setItem("invite", state.param.invite);
        }
        //红包码
        if (state.param.c) {
          localStorage.setItem("shareBonusCode", state.param.c);
          const router = useRouter();
          router.push({
            name: "receiveBonus",
          });
        }
        //我的红包码
        if (state.param.s) {
          localStorage.setItem("shareBonusReceiveCode", state.param.s);
        }
        //邀请码统计
        if(state.param.invite && localStorage.getItem("inviteVisit") === null){
          state.inviteCode = localStorage.getItem("invite")
          axios({
            url: "/api/system/visit",
            method: "get",
            params: { inviteCode: state.inviteCode}
          }).then(() => {
            localStorage.setItem("inviteVisit",state.inviteCode);
          })      
        }
      }
    });
    onMounted(() => {
      window.onbeforeunload = function () {
        var storage = window.localStorage;
        storage.removeItem("invite");
        storage.removeItem("shareBonusCode");
        storage.removeItem("downloadApp");
      };
    });
    return {
      ...toRefs(state),
    };
  },
};
window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};




</script>



<style scoped>
.Skright-enter-active,
.Skright-leave-active,
.Skleft-enter-active,
.Sklef-leave-active {
  transition: all 600ms;
  transition: 0.5s;
  position: absolute;
  top: 0;
}

.Skright-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.Skright-leave-to {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.Skleft-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.Skleft-leave-to {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.no-access {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30000;
  background: #000;
}

.no-access p {
  text-align: center;
  color: #fff;
  padding-top: 300px;
  font-size: 20px;
}
.app-notice{ position: fixed; left: 0; right: 0; top: 0; bottom: 0; background: url(./assets/images/bg66.png); z-index: 2000; }
.app-notice .app-notice-c{ position: absolute; left:calc(50% -  150px); top: calc(50% -  100px); border-radius:8px;
  color: #fff; width: 300px;  background: #000; }
.app-notice .app-notice-b{ padding: 10px;font-size: 13px;min-height: 150px;}
.app-notice .app-notice-b h4{ font-size: 16px; margin: 10px 0;}
.app-notice-f{ text-align: right; padding: 10px;}
.app-notice-f .btn-t{ margin-right: 5px; border: 0; background: #ffe477; color: #392500;  border-radius: 10px;font-size: 10px; 
  padding: 0px 20px; line-height: 20px; opacity: .8;  height: 20px;overflow: hidden; display: inline-block; }

#downloadDialogTop{ position: absolute; top:0; right: 0; left: 0; z-index: 2001; }
#downloadDialogTop img{ width: 100%; box-shadow: 0 1px 7px #143900}
#downloadDialogTop .close{ background: #fff; width: 20px; height: 20px; border-radius: 6px; position: absolute; right: 3px; top: 5px; 
  color: #333; border: 1px solid #eee; text-align: center;}

#downloadDialogPopup{position: fixed; top:200px; left: calc(50% - 140px);  z-index: 2001; width: 240px; height: 120px; 
  background: #fff; box-shadow: 0 1px 7px #666;border-radius: 10px; padding: 20px;}

#downloadDialogPopup h3{ line-height: 30px; margin: 0; padding: 0; text-align: center;}
#downloadDialogPopup p{ margin: 5px 0 15px 0;}
#downloadDialogPopup .btnbox{ text-align: center; margin-top: 10px; display: flex;}
#downloadDialogPopup .btnbox .btn{ display: inline-block; margin: 0 10px; width: 100px; height: 30px; line-height: 30px; 
    background: #fff;border-radius: 3px; border: 1px solid #666;text-decoration:none; color: #333;}
#downloadDialogPopup .btnbox .btn.gg{background:#000 url(./assets/images/goglePlay.jpg) center center no-repeat; background-size: 90% auto;}
</style>



