<template>
  <div>
    <nut-navbar
      :left-show="false"
      @on-click-icon="iconClick"
      :title="$t('invite.h1')"
      titIcon="horizontal"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed nut-navbar-b0"
    >
    </nut-navbar>

    <div class="agent-main">
      <div class="agent-top">
        <div class="agent-info">
          <div class="agent-info-l">
            <div>
              <span>{{$t('invite.agentAmount')}}</span>
              <strong>
                {{ $filters.currencySymbol(inviteInfo.availableAmount) }}
                <nut-button plain type="info" size="mini" @click="withdrawClick('cash')">{{$t('invite.withdraw')}}</nut-button>
                <nut-button v-show="!commonInfo.pureMode" plain type="info" size="mini" @click="transferClick">{{$t('withdrawal.transfer')}}</nut-button>
              </strong>
            </div>
            <div class="flbd">
              <div class="fl">
                <span @click="infoToast($t('invite.infoToast'))">
                  {{$t('invite.totalPeople')}} 
                  <nut-icon name="ask" class="copyicon" style="vertical-align: bottom; font-size: 14px;height: 16px;"></nut-icon>
                </span>
                <strong>{{ inviteInfo.inviteCount }}</strong>
              </div>
              <div class="fl">
                <span>{{$t('invite.effectiveNum')}}</span>
                <strong>{{effectiveCount}}</strong>
              </div>
            </div>
          </div>
          <div class="agent-lv">
            <strong>{{ inviteInfo.vipLevel }}</strong>
          </div>
        </div>

        <!--  代理pro -->
        <!-- <div class="agent-vip" @click="growthPlanPro" v-if="forbidCashUser">
          <img class="img100" src="../assets/images/bg67d.png" />
        </div>

        <div class="agent-vip" @click="growthPlan" v-else>
          <img class="img100" :src="require('../assets/images/' + imgUrl + 'bg67c.png')" />
        </div>

        <div @click="growthPlanPlus" v-if="agentInviteeEnoughRechargeTaskAvailable && !forbidCashUser" >
          <img class="img101" src="../assets/images/bg201.jpg" />
        </div> -->

        <div class="agent-vip" @click="growthPlan">
          <img class="img100" :src="require('../assets/images/' + imgUrl + 'bg67c.png')" />
        </div>

        <div @click="growthPlanPlus" v-if="agentInviteeEnoughRechargeTaskAvailable" style="position: relative;">
          <img class="img101" src="../assets/images/bg201.jpg" />
          <div style=" position: absolute;  bottom: 4px; width: 100%; font-weight: 600; font-size: 15px; text-align: center; color: #fff;
          text-shadow: 0 2px #000, 2px 0  #000, -2px 0  #000, 0 -2px #000; ">Event end time: <strong>15-05-2024  00:00</strong></div>
        </div>


      </div>

      <!--  代理pro -->
      <!-- <div class="ap-bn" v-if="country2 === 'in' && !forbidCashUser">
        <img v-show="periodTime"  @click="toAgentSupportPlan" class="img100" src="../assets/images/bg316.png" />
        <img @click="showRound = true" class="img100" src="../assets/images/bg317.png" />
      </div>

      <div  v-else-if="country2 === 'in' && forbidCashUser">
        <img @click="showRound = true" class="img100" src="../assets/images/bg317b.png" />
      </div>

      <img v-else  @click="showRound = true" class="img100" :src="require('../assets/images/' + imgUrl + 'bg67.png')" /> -->

      <div class="ap-bn"  v-if="country2 === 'in'">
        <img v-show="periodTime"  @click="toAgentSupportPlan" class="img100" src="../assets/images/bg316.png" />
        <img @click="showRound = true" class="img100" src="../assets/images/bg317.png" />
      </div>

      <img v-else  @click="showRound = true" class="img100" :src="require('../assets/images/' + imgUrl + 'bg67.png')" />

    
      <!-- <img  @click="toAgentSupportPlan" class="img100" v-show="periodTime" :src="require('../assets/images/' + imgUrl + 'bg67b.png')" />

      <img  @click="showRound = true" class="img100" :src="require('../assets/images/' + imgUrl + 'bg67.png')" /> -->


      <div class="agent-table" v-show="domShow">
        
        <!-- h4>{{$t('invite.contribution')}}<span class="r-tx" v-show="asList.length > 4">{{$t('invite.more')}} ></span></h4> -->

        <ul class="tab-hd">
          <li :class="tabActive ? 'active' : ' '" @click="tabToggle('active')"><span>{{$t('invite.rules')}}</span></li>
          <li :class="!tabActive ? 'active' : ' '" @click="tabToggle()"><span>{{$t('invite.bonus')}}</span></li>
        </ul>

        <div class="tg-btn" v-if="country2 === 'in' && template === 's1'" style="float: right; margin-top: -32px;z-index: 200;position: inherit;">
          <img src="../assets/images/bg312.png" style="height: 28px !important;" @click="toTelegram" />
        </div>

        <div class="tg-btn" v-else-if="country2 === 'br' && template === 's1'" style="float: right; margin-top: -32px;z-index: 200;position: inherit;">
          <img src="../assets/images/bg313.png" style="height: 28px !important;" @click="toTelegram" />
        </div>

        <div class="tab-bd" v-if="tabActive"> 
          <div class="tab-bd-img">
            <dl>
              <dt>{{$t('invite.you')}}</dt>
              <dd style="font-size: 12px; line-height: 12px;">{{$t('invite.youTitle')}}</dd>
            </dl>
            <!--
            <dl v-for="(item, index) in asList" :key="index">
              <dt>{{$t('invite.level')}}:{{item.affinity}}</dt>
              <dd><span>{{$t('invite.label1')}}:</span><strong>{{item.subBetCommissionRatio}}%</strong></dd>
            </dl>
            -->
            <dl>
              <dt>{{$t('invite.Grade')}}:1</dt>
              <dd><span>{{$t('invite.Bonuss')}}:</span><strong>30%</strong></dd>
            </dl>
            <dl>
              <dt>{{$t('invite.Grade')}}:2</dt>
              <dd><span>{{$t('invite.Bonuss')}}:</span><strong>20%</strong></dd>
            </dl>
            <dl>
              <dt>{{$t('invite.Grade')}}:3</dt>
              <dd><span>{{$t('invite.Bonuss')}}:</span><strong>7%</strong></dd>
            </dl>
            <dl>
              <dt>{{$t('invite.Grade')}}:4</dt>
              <dd><span>{{$t('invite.Bonuss')}}:</span><strong>3%</strong></dd>
            </dl>
          </div>

          <div class="tab-bd-p">
            <h5>{{$t('invite.rulesTitle2')}}</h5>
            <p>{{$t('invite.rulesText21')}}</p>
            <p>{{$t('invite.rulesText22')}}</p>
          </div>

          <div class="tab-bd-p">
            <h5>{{$t('invite.rulesTitle3')}}</h5>
            <p>{{$t('invite.rulesText31')}}</p>
            <p>{{$t('invite.rulesText32')}}</p>
          </div>

          <div class="tab-bd-p">
            <h5>{{$t('invite.rulesTitle1')}}</h5>
            <p>{{$t('invite.rulesText11')}}</p>
            <p>{{$t('invite.rulesText12')}}</p>
          </div>

          <div class="tab-bd-p">
            <h5>{{$t('invite.rulesTitle4')}}</h5>
            <p>{{$t('invite.rulesText41')}}</p>
          </div>

        </div>
        <div class="tab-bd" v-else>
          <table class="table-th">
            <tr>
              <th ></th>
              <td></td>
            </tr>
            <tr>
              <th >{{$t('invite.inviteCount')}}</th>
              <td></td>
            </tr>
            <tr>
              <th >{{$t('invite.inviteCommissionAmount')}}</th>
              <td></td>
            </tr>
            <tr>
              <th>{{$t('invite.effectiveCount')}}</th>
              <td></td>
            </tr>
            <tr>
              <th>{{$t('invite.effectiveBonus')}}</th>
              <td></td>
            </tr>
            <tr>
              <th>{{$t('invite.subBetCommissionAmount')}}</th>
              <td></td>
            </tr>
            <tr>
              <th>{{$t('invite.totalCommissionAmount')}}</th>
              <td></td>
            </tr>
          </table>
          <div class="table-td">
            <table>
              <tr>
                <th v-for="(item, index) in asList" :key="index">{{$t('invite.affinity')}}{{item.affinity}}</th>
              </tr>
              <tr>
                <td v-for="(item, index) in asList" :key="index">{{ index > 0 ? '--' : item.inviteCount }}</td>
              </tr>
              <tr>
                <td v-for="(item, index) in asList" :key="index">{{ index > 0 ? '--' : $filters.currencySymbol(item.inviteCommissionAmount) }}</td>
              </tr>
              <tr>
                <td v-for="(item, index) in asList" :key="index">{{ index > 0 ? '--' : item.effectiveCount }}</td>
              </tr>
              <tr>
                <td v-for="(item, index) in asList" :key="index">{{ index > 0 ? '--' : $filters.currencySymbol(item.effectiveInviteCommissionAmount) }}</td>
              </tr>

              <tr>
                <td v-for="(item, index) in asList" :key="index">{{ $filters.currencySymbol(item.subBetCommissionAmount) }}</td>
              </tr> 
              <tr>
                <td v-for="(item, index) in asList" :key="index">{{ $filters.currencySymbol(item.totalCommissionAmount) }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="shares-btn" v-show="domShow">
        <p>{{$t('invite.info1')}}{{ registerReward }}{{$t('invite.info2')}}</p>
      </div>

    <!--**************************************** 近期邀请奖励弹框 ****************************************-->
    <!-- <nut-popup pop-class="pop-reward" v-model:visible="rewardPopup">
      <div class="hd"></div>
      <div class="bd">
        <h4>{{ $t('invite.NewAgencyIncome') }}</h4>
        <h5>+{{ $filters.currencySymbol(inviteInfo.recentCommissionAmount) }}</h5>
        <p>
          {{ $t('invite.NewInvites') }} 
          <strong v-if="effectiveCount <= inviteInfo.recentInviteCount">+{{effectiveCount}}</strong>
          <strong v-else>+{{inviteInfo.recentEffectiveCount}}</strong>
        </p>
      </div>
      <div class="btn">
        <nut-button type="primary" @click="showRound = true">{{ $t('invite.EarnMoreNow') }}</nut-button>
        <p>{{ $t('invite.Last30Days') }}</p>
      </div>
    </nut-popup> -->


      <nut-popup position="bottom" closeable round style="height: '53%';z-index:3000" v-model:visible="showRound">
        <div class="copy-link">
          <h4>{{$t('invite.h4')}}</h4>
          <strong class="ft30">{{ inviteInfo.inviteCode }}</strong>
          <p>{{$t('invite.h4Title')}}</p>
          <nut-button block type="info" @click="copyLink(inviteInfo.inviteCode)">{{$t('invite.btn')}}</nut-button>
          <p>{{$t('invite.to')}}</p>
        </div>

        <div class="copy-link-dl">
          <dl>
            <ShareNetwork v-for="network in networks" :key="network.network"
                :network="network.network"
                :url="sharing.url"
                :title="sharing.title"
                :description="sharing.description"
                :quote="sharing.quote"
                :hashtags="sharing.hashtags"
                :twitterUser="sharing.twitterUser"
              >
              <i class="fab fah fa-lg fa-twitter"></i>
              <dd :class="network.class"><span>{{ network.name }}</span></dd>
            </ShareNetwork>
          </dl>
        </div>
      </nut-popup>
    </div>

    <!--**************************************** 活动弹框 ****************************************-->
    <nut-popup pop-class="pop-ab" v-model:visible="abPopup">
      <div class="bd" style="background: none !important;" >

        <!-- IN -->
        <nut-swiper v-if="abPopupIN" :init-page="1" :pagination-visible="true"  pagination-color="#426543" auto-play="6000" :is-preventDefault="false">
          
          <!-- <nut-swiper-item v-if="periodTime"  @click="toAgentSupportPlan">
            <img  :src="require('../assets/images/in/EN/S1/bg903.png')" />
          </nut-swiper-item> -->

          <!-- <nut-swiper-item v-if="agentInviteeEnoughRechargeTaskAvailable"  @click="growthPlanPlus">
            <img  :src="require('../assets/images/in/EN/S1/bg905.png')" />
          </nut-swiper-item> -->

          <!-- <nut-swiper-item v-if="show_m9" @click="abPopup = false">
            <img  :src="require('../assets/images/in/EN/S1/bg904.png')" />
          </nut-swiper-item> -->
          
          <nut-swiper-item v-if="show_w4" @click="abPopup = false">
            <img  :src="require('../assets/images/in/EN/S1/bg902.png')" />
          </nut-swiper-item>

        </nut-swiper>

        <!-- VN -->
        <nut-swiper v-if="abPopupVN" :init-page="1" :pagination-visible="true"  pagination-color="#426543" auto-play="3000" :is-preventDefault="false">
          <nut-swiper-item @click="abPopup = false">
            <img  :src="require('../assets/images/vn/VI/bg904.jpg')" />
          </nut-swiper-item>
        </nut-swiper>

        <!-- BR -->
        <nut-swiper v-if="abPopupBR" :init-page="1" :pagination-visible="true"  pagination-color="#426543" auto-play="0" :is-preventDefault="false">
          <nut-swiper-item  @click="abPopup = false">
            <img :src="require('../assets/images/br/PT/S1/bg903.jpg')" />
          </nut-swiper-item>
        </nut-swiper>
        
      </div>
      <nut-button type="primary" @click="abPopup = false">{{ $t('game.gotitBtn') }}</nut-button>
    </nut-popup>



    <tabbar :activeIndex="tabbarActiveIndex"></tabbar>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted,getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
import axios from "axios";
import {Dialog, Toast} from "@nutui/nutui";
import { getCountry } from "@/assets/js/environment";

export default {
  components: {
    tabbar: TabbarMain,
  },
  data() {
    return {
      country2: '',
      template: '',
      s: '',
      imgUrl: '',
      sharing: {
        url: this.$t('invite.url'),
        title: this.$t('invite.title'),
        description: this.$t('invite.description'),
        quote: this.$t('invite.quote'),
        hashtags: this.$t('invite.hashtags'),
        twitterUser: this.$t('invite.twitterUser')
      },
      i18n:{
        loading: this.$t('global.loading'),
        copied: this.$t('global.Your_invitation_link_has_been_copied'), 
      }
    };
  },
  created() {
    this.country2 = getCountry()
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.template = document.querySelector('body').getAttribute('s')
    this.s = document.querySelector('body').getAttribute('s');
  },
  methods: {
    infoToast(msg){
      Dialog({
        content: msg,
        okText: this.$t('global.okText'),
        noCancelBtn: true
      });
    },
    toTelegram(){
      window.open(this.commonInfo.telegramChannelUrl,"_self");
    }
  },
  setup() {
    const _data = getCurrentInstance();
    const state = reactive({
      commonInfo: JSON.parse(window.localStorage.getItem("commonInfo")),
      tabActive:true,
      showRound: false,
      rewardPopup: false,
      domShow:false,
      country: '',
      s: '',
      currentDate:{
        year: '',
        month: '',
        day: '',
        week: '',
        hours: '',
      },
      showDate: {
        year: '',
        month: '',
        day: '',
        week: '',
      },
      EndDate: {
        year: '',
        month: '',
        day: '',
        week: '',
      },
      
      abPopup: false,
      abPopupIN: false,
      abPopupVN: false,
      abPopupBR: false,
      show_w4: false,
      show_m9: false,
      forbidCashUser:false,
      agentInviteeEnoughRechargeTaskAvailable:false,
      periodTime: '',
      tabbarActiveIndex: 1,
      effectiveCount: "",
      registerReward:'',
      asList:[],
      inviteInfo: {
        availableAmount: "",
        inviteCode: "",
        inviteCount: "",
        inviteeRewardAmount: "",
        maxInviteRewardAmount: "",
        vipLevel: "",
        recentInviteCount: 0,
        recentEffectiveCount: 0,
        recentCommissionAmount: 10,
      },
      networks: [
        { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', class: 'i1' },
        { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', class: 'i2' },
        { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', class: 'i3' },
        { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', class: 'i4' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', class: 'i5' }
      ]
    });
    const router = useRouter();
    const iconClick = () => {
      router.push({
        path: "transactionsHistoryList",
        query: {
          listType: 'cash'
        }
      })
    };
    
    const tabToggle = (cls) => {
      state.tabActive = cls === 'active' ? true : false
    };
    const withdrawClick = (t) => {
      router.push({
        path: 'withdrawalBalance',
				query: {
					amountType: t
				}
			})
    };
    const growthPlan = () => {
      router.push({
        name: "agentGrowth",
      });
    };
    const growthPlanPlus = () => {
      router.push({
        name: "agentGrowthPlus",
      });
    };
    const growthPlanPro = () => {
      router.push({
        name: "promotionPartner",
      });
    };
    const transferClick = () => {
        router.push({
          name: "cashTransfer",
        });
    };
    const toAgentSupportPlan = () => {
        router.push({
          name: "agentSupportPlan",
        });
    };

    const copyLink = () => {
      var cInput = document.createElement("input")
      cInput.value = _data.data.sharing.url
      document.body.appendChild(cInput)
      cInput.select()
      document.execCommand("copy")
      Toast.success(_data.data.i18n.copied)
      document.body.removeChild(cInput)
    };
    const methods = {
      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      },
      
    };

    onMounted(() => {
      methods.textToast(_data.data.i18n.loading);
      axios
        .get("/api/invite", {})
        .then(function (response) {
          state.agentInviteeEnoughRechargeTaskAvailable = response.data.data.agentInviteeEnoughRechargeTaskAvailable;
          if(state.agentInviteeEnoughRechargeTaskAvailable){
            state.agentInviteeEnoughRechargeTaskAvailable = ((new Date(2024,4,15,0,0,0).getTime()) - new Date().getTime()) > 0
          }
          state.asList = response.data.data.affinityStatisticsList;
          state.inviteInfo.availableAmount =response.data.data.fundAccount.availableAmount;
          state.inviteInfo.inviteCode = response.data.data.inviteCode;
          localStorage.setItem("inviteCode", response.data.data.inviteCode);
          state.inviteInfo.inviteCount = response.data.data.inviteCount;
          state.inviteInfo.inviteeRewardAmount = response.data.data.inviteeRewardAmount;
          state.inviteInfo.maxInviteRewardAmount = response.data.data.maxInviteRewardAmount;
          state.inviteInfo.vipLevel = response.data.data.vipLevel;
          state.effectiveCount = response.data.data.effectiveCount;
          state.forbidCashUser = response.data.data.forbidCashUser;
          Toast.hide("loading");
          state.domShow = true
          state.registerReward = state.commonInfo.registerReward;
          _data.data.sharing.url = state.commonInfo.frontBaseUrl + '?invite=' + state.inviteInfo.inviteCode
          state.inviteInfo.recentInviteCount = response.data.data.recentInviteCount
          state.inviteInfo.recentEffectiveCount = response.data.data.recentEffectiveCount
          state.inviteInfo.recentCommissionAmount = response.data.data.recentCommissionAmount
          if(response.data.data.startTime){
            state.periodTime = response.data.data.startTime.slice(6,8) + '/' + response.data.data.startTime.slice(4,6) + '~' + response.data.data.endTime.slice(6,8) + '/' + response.data.data.endTime.slice(4,6)
          }
          const d = new Date()
          state.currentDate = {
            year: d.getFullYear(),
            month: d.getMonth() + 1, 
            day: d.getDate(),
            week: d.getDay(), //星期日 = 0
            hours: d.getHours(), //24小时制 0～24
          }
          // state.showDate = {
          //   year: '',
          //   month: '',
          //   day: '',
          //   week: '',
          // }
          // state.EndDate = {
          //   year: '',
          //   month: '',
          //   day: '',
          //   week: '',
          // }

          state.country = getCountry()

          switch (state.country) {
            case 'in':
              state.abPopupIN = true
              //星期4活动
              state.show_w4 = (d.getDay() === 4)
              //每月9日活动
              state.show_m9 = (d.getDate() >= 8) && (d.getDate() <= 10)

              state.abPopup = (
                (state.abPopupIN && state.show_w4) || 
                (state.abPopupIN && state.show_m9) )  ? true : false
              // if(state.forbidCashUser){
              //   state.abPopup = false
              // }
              //state.abPopup = ((state.abPopupIN && state.show_w4) || (state.abPopupIN && state.show_m9) || state.periodTime )  ? true : false
              break;
            case 'vn':
              if( (d.getMonth() + 1  === 5) && (d.getDate() > 13)){
                state.abPopup = false
                state.abPopupVN = false
              }else{
                state.abPopup = false
                state.abPopupVN = false
              }
              break;
            case 'br':
              state.abPopup = false
              state.abPopupBR = false
              break;
          }
          //设置近期奖励显示
          if(parseInt(state.inviteInfo.recentCommissionAmount) > 0){
            const d = new Date()
            if(localStorage.getItem('recentCommissionAmountPopup') === null){
              state.rewardPopup = true
              localStorage.setItem("recentCommissionAmountPopup", d.getDate().toString());
            }else if(localStorage.getItem('recentCommissionAmountPopup') === d.getDate().toString()){
              state.rewardPopup = false
            }else{
              state.rewardPopup = true
              localStorage.setItem("recentCommissionAmountPopup", d.getDate().toString());
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          Toast.hide("loading");
        })

        
    });
    return {
      ...toRefs(state),
      ...methods,
      iconClick,
      tabToggle,
      withdrawClick,
      growthPlan,
      growthPlanPlus,
      growthPlanPro,
      copyLink,
      transferClick,
      toAgentSupportPlan,
      
    };
  },
};
</script>

<style scoped>


.img100{ width:96%; margin:0 2%;  }
.agent-table h4{ margin-top:0;}
.tab-hd{ height: 34px;position: relative; z-index: 105;}
.tab-hd li{ float: left;height: 30px; line-height: 30px; padding: 0 30px;border: 1px solid #ccc; border-top: 5px solid #ccc; border-bottom: 0;border-radius: 5px 5px 0 0; margin-right:3px;}
.tab-hd li.r{float: right;padding: 0px;border: 0px;border-radius:0;}
.tab-hd li.active{ border: 1px solid #3d2505; border-top: 5px solid #3d2505;  border-bottom: 0; height: 31px; background: #fff; font-weight: 600;  }
.tab-hd li span{ font-size: 14px; color: #333;}
.tab-hd li.active span{ color: #3d2505;}
.tab-bd { border-top: 1px solid #3d2505;position: relative; z-index: 103; padding-top:10px;background: #fff;min-height: 270px;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1); padding-bottom:10px;}

.tab-bd-img{ background:url(../assets/images/bg60.png) 0px 0px no-repeat;background-size: 475px auto; height: 280px;}
.tab-bd-img dl{ height: 56px; font-size: 16px;}
.tab-bd-img dd span{ margin-right: 10px;font-size: 12px;}
.tab-bd-img dd strong{ color: #b91201;}
.tab-bd-img dl:nth-child(1){ margin-left: 140px; height: 52px;}
.tab-bd-img dl:nth-child(2){ margin-left: 155px;}
.tab-bd-img dl:nth-child(3){ margin-left: 180px;}
.tab-bd-img dl:nth-child(4){ margin-left: 205px;}
.tab-bd-img dl:nth-child(5){ margin-left: 230px;}

.tab-bd-img dl:nth-child(1) dt{ color: #a97f0c;}
.tab-bd-img dl:nth-child(2) dt{ color: #2f75b3;}
.tab-bd-img dl:nth-child(3) dt{ color: #385623;}
.tab-bd-img dl:nth-child(4) dt{ color: #863a08;}
.tab-bd-img dl:nth-child(5) dt{ color: #2a4678;}

.tab-bd-p{ background: #fff;border-radius: 5px; margin: 0px; padding:5px 10px;}
.tab-bd-p h5 { margin: 0; border-left:3px solid #783b12; color: #783b12; padding-left:10px; height:16px; line-height: 16px;}
.tab-bd-p p{ margin: 3px 0; color:#cdb67e; line-height: 16px; font-size: 12px;}
.agent-main {
  margin-top: 40px;
}
.nut-navbar, .nut-navbar-b0, .nut-navbar-b0 .nut-navbar {
  box-shadow: 0 0 0 !important;
}
.agent-top {
  margin-top: -1px;
  padding: 10px;
  background: url(../assets/images/bg05c.png) 0 -120px no-repeat;
  background-size: 100% 300px;
}
.agent-info {
  border-radius: 10px;
  background: url(../assets/images/bg16.png) center center no-repeat;
  background-size: 100% 100%;
  height: 140px;
  position: relative;
  z-index: 2002;
  color: #fff;
  z-index: 320; border: 2px solid #000;
}
.agent-info .agent-lv {
  position: absolute;
  top: 15px;
  right: 10px;
  width: 80px;
  height: 80px;
  text-align: center;
  background: url(../assets/images/bg17-2.png) center center no-repeat;
  background-size: 80px 80px;
}
.agent-info .agent-lv strong {
  line-height: 40px;
  display: block;
  padding-top: 20px;
  font-size: 26px;
  color: #fff86a;
  margin-bottom: 15px;
}
.agent-info .agent-lv span {
  font-size: 16px;
}
.agent-info .nut-button--plain.nut-button--info {
  color: #993510 !important;
  background: #ffffff !important;
  border-color: #993510 !important;
  margin-left: 5px;
  vertical-align: middle;
  font-weight: 300;
  font-size: 10px;
  padding: 0 5px;
}
.agent-info-l {
  padding: 20px;
}
.agent-info-l strong {
  line-height: 40px;
  display: block;
  font-size: 22px;
  font-weight: 400;
  color: #ffba14;
}
.flbd .fl {
  float: left;
  margin-right: 25px;
  height: 54px;
}
.flbd .fl:nth-last-child(1) {
  margin-right: 0;
}
.flbd .fl strong {
  font-size: 16px;
}
.agent-vip {
  background: #333333;
  height: auto;
  border-radius: 0 0 10px 10px;
  z-index: 90;
  margin-top: -10px;
  position: relative;
  color: #aa9d75;
  text-align: left;
  padding: 10px 0 0 0;
}
.agent-vip .img100{width: 100% !important; margin: 0;margin-bottom: -10px;}
.ap-bn{ position: relative; margin: 0px 10px 0 10px;} 
.ap-bn .img100{width: 49% ;margin:0;}
.ap-bn .img100:last-child{margin-left:2%;}
.ap-bn .time{ position: absolute; left:20px; top:0; padding: 2px 15px; height: 18px; line-height: 20px; opacity: .8;
   font-size: 14px; color: #fff; background: #4b64ff; border-radius:20px 5px 20px 5px; font-style: italic;}

.agent-vip .r {
  float: right;
  padding-right: 10px;
  color: #f5ce95;
}
.shares-btn {
  padding: 0px 10px 60px 10px;
  position: relative;
}
.shares-btn .icon {
  position: absolute;
  background: url(../assets/images/bg11.png) no-repeat;
  width: 60px;
  height: 60px;
  z-index: 99;
  left: 10px;
  top: 0px;
}
.shares-btn p {
  padding: 0 10px 10px 10px;
  margin: 0;
  color: #783b12;
}
.r-tx{ float: right; color: #ccc !important; font-weight: 300 !important;}
.agent-table {
  margin: 10px;
  overflow: hidden;
  position: relative;
  
}
.agent-table h4 {
  margin-bottom: 5px;
  color: #333;
}
.agent-table table {
  width: 100%;
}
.agent-table table th {
  border: 1px #dedbca solid;
  background: #eceadc;
  line-height: 16px;
  height: 38px;
  padding: 0 5px;
  font-weight: 400;
  color: #af9041; text-align: left; width: 150px;

}
.agent-table table td {
  border: 1px #dedbca solid;
  line-height: 16px;
  height: 38px;
  padding: 0 5px;
  text-align: center;
}

.agent-table .table-th{
  position: absolute;
  width: calc(100% - 5px);
  left:5px;
  z-index: 100;
}
.agent-table .table-th th{
  width: 85px;
}
.agent-table .table-td{
  position: relative;
  z-index: 110;
  margin-left: 101px;
  overflow-x:scroll;
  overflow-y: hidden;
}
.agent-table .table-td table{
  width: auto;
}
.agent-table .table-td td{ min-width:45px;}
.copy-link {
  padding: 20px;
  padding-bottom: 0;
  text-align: center;
}
.copy-link h4 {
  margin: 5px 0 30px 0;
  font-weight: 300;
}
.copy-link .ft30 {
  font-size: 40px;
  font-weight: 300;
  padding: 10px;
  background: #fffdcb;
  border-radius: 10px;
}
.copy-link p {
  margin: 20px 0;
  color: #783b12;
}
.copy-link-dl dl {
  margin-top: 0px;
}
.copy-link-dl dl {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.copy-link-dl dl dd {
  align-items: flex-start;
  text-align: center;
  margin: 5px;
  font-size: 10px;
  padding-top: 50px;
  color: #666;
  text-decoration:none;
}
.copy-link-dl dl dd.i1 {
  background: url(../assets/images/bg21.png) center top no-repeat;
  background-size: 40px 40px;
}
.copy-link-dl dl dd.i2 {
  background: url(../assets/images/bg22.png) center top no-repeat;
  background-size: 40px 40px;
}
.copy-link-dl dl dd.i3 {
  background: url(../assets/images/bg23.png) center top no-repeat;
  background-size: 40px 40px;
}
.copy-link-dl dl dd.i4 {
  background: url(../assets/images/bg24.png) center top no-repeat;
  background-size: 40px 40px;
}
.copy-link-dl dl dd.i5 {
  background: url(../assets/images/bg25.png) center top no-repeat;
  background-size: 40px 40px;
}
.copy-link-dl dl dd.i6 {
  background: url(../assets/images/bg26.png) center top no-repeat;
  background-size: 40px 40px;
}

.img101{ border-radius: 10px; width: 100%; height: auto; margin-top: 10px;}

</style>

<style>
.pop-ab {
  padding: 0px !important;
  width: 100% !important;
  min-height: 100px;
  top: 50%;
  border-radius: 0px;
  background: transparent !important;  text-align: center;overflow-y: hidden !important;
}
.pop-ab .nut-button--round{height: 30px !important; margin-top:5px;}
.pop-ab .bd{ margin: 0; padding: 3px 0px !important; background: #000;}
.pop-ab img{ width: 100% !important; height: auto; margin: 0; max-width: 400px !important;}
.pop-ab .nut-swiper-inner{ height: auto !important; }

.pop-reward{ height: 426px;  width: 330px;text-align: center;
  background:transparent url(../assets/images/in/EN/S1/bg300.png) 0px 0px no-repeat !important ;background-size: 100% auto !important;}
.in .pop-reward{background:transparent url(../assets/images/in/EN/S1/bg300.png) 0px 0px no-repeat !important ;background-size: 100% auto !important;}
.ph .pop-reward{background:transparent url(../assets/images/ph/EN/S1/bg300.png) 0px 0px no-repeat !important ;background-size: 100% auto !important;}
.br .pop-reward{background:transparent url(../assets/images/br/PT/S1/bg300.png) 0px 0px no-repeat !important ;background-size: 100% auto !important;}
.vn .pop-reward{background:transparent url(../assets/images/vn/VI/S1/bg300.png) 0px 0px no-repeat !important ;background-size: 100% auto !important;}
.pop-reward .hd{height: 140px;}
.pop-reward .bd {height: 195px;}
.pop-reward .bd h4{ margin: 0; line-height: 40px; font-size: 18px;color: #7e3500;}
.pop-reward .bd h5{ margin: 5px 0 10px 0;line-height: 50px;font-size: 35px; color: #ff8a00; font-weight: 600;}
.pop-reward .bd p{ margin: 8px 0; line-height: 20px;font-size: 16px;color: #7e3500;}
.pop-reward .bd p strong{font-size: 18px;color: #ff8a00; margin-right: 5px;}
.pop-reward .nut-button--primary{ background: none !important; color: #822e00; font-weight: 600;font-size: 16px;}
.pop-reward .btn p{ margin: 5px 0 0 0; color: #fff; opacity: .2;font-size: 12px;}
</style>
